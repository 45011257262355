<template>
  <Modal v-model="modal" width="600" :title="modalTitle" footer-hide>
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
      <FormItem label="商品名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入商品名称" style="width: 250px;"></Input>
      </FormItem>

      <Row class="m-t-20">
        <i-col span="12">
          <FormItem label="置换价" prop="amount">
            <InputNumber :min="0" v-model="formValidate.amount" style="width: 150px;"></InputNumber>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="门市价" prop="useAmount">
            <InputNumber :min="0" v-model="formValidate.useAmount" style="width: 150px;"></InputNumber>
          </FormItem>
        </i-col>
      </Row>

      <Row class="m-t-20 m-b-20">
        <i-col span="12">
          <FormItem label="商品类型" prop="productType">
            <RadioGroup v-model="formValidate.productType">
              <Radio label="1">实物商品</Radio>
              <Radio label="2">电子卡券</Radio>
            </RadioGroup>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="商品数量" prop="number">
            <InputNumber :min="0" v-model="formValidate.number"  style="width: 150px;"></InputNumber>
          </FormItem>
        </i-col>
      </Row>

      <Row class="m-t-20 m-b-20">
        <i-col span="12">
          <FormItem label="有效期" prop="validityStartDate">
              <DatePicker type="date" placeholder="选择开始日期" v-model="formValidate.validityStartDate"  style="width: 150px;"></DatePicker>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="-" prop="validityEndDate">
              <DatePicker type="date" placeholder="选择结束日期" v-model="formValidate.validityEndDate"  style="width: 150px;"></DatePicker>
          </FormItem>
        </i-col>
      </Row>

      <FormItem>
          <Button type="primary" @click="handleSubmit('formValidate')"  style="width: 100px;">
            <span>{{ modalTitle.indexOf('新增')>-1? '提交': '保存' }}</span>
          </Button>
      </FormItem>
   </Form>
  </Modal>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { addProduct, updateProduct } from '@/api/displace/contract'

export default {
  data () {
    return {
      modal: false,
      modalTitle: '',
      contractId: null,
      modifyId: null,

      formValidate: {
        name: '',
        amount: null,
        number: null,
        productType: '1',
        useAmount: null,
        validityStartDate: null,
        validityEndDate: null
      },
      ruleValidate: {
        name: [
          { required: true, message: '商品名称不能为空', trigger: 'blur' }
        ],
        amount: [
          { required: true, type: 'any', message: '置换单价不能为空', trigger: 'blur' }
        ],
        number: [
          { required: true, type: 'any', message: '商品数量不能为空', trigger: 'blur' }
        ],
        useAmount: [
          { required: true, type: 'any', message: '产品单价不能为空', trigger: 'blur' }
        ],
        validityStartDate: [
          { required: true, type: 'date', message: '商品有效开始日期不能为空', trigger: 'change' }
        ],
        validityEndDate: [
          { required: true, type: 'date', message: '商品有效结束日期不能为空', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    showModal (contractId, updateBean) {
      this.modal = true
      this.$refs.formValidate.resetFields()

      if (updateBean) {
        this.modalTitle = '编辑商品'
        this.modifyId = updateBean.id
        this.formValidate = {
          name: updateBean.name,
          amount: updateBean.amount,
          number: updateBean.number,
          productType: updateBean.productType.toString(),
          useAmount: updateBean.useAmount,
          validityStartDate: updateBean.validityStartDate,
          validityEndDate: updateBean.validityEndDate
        }
      } else {
        this.modalTitle = '新增商品'
        this.contractId = contractId
      }
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.formValidate.validityStartDate = ParseDate(this.formValidate.validityStartDate)
          this.formValidate.validityEndDate = ParseDate(this.formValidate.validityEndDate)

          if (this.modalTitle.indexOf('新增') > -1) {
            const submitBean = Object.assign(this.formValidate, { contractId: this.contractId })

            addProduct(submitBean).then(res => {
              this.modal = false
              this.$emit('on-success')
              this.$Notice.success({ desc: '新增商品成功' })
            })
          } else {
            const submitBean = Object.assign(this.formValidate, { cdId: this.modifyId })

            updateProduct(submitBean).then(res => {
              this.modal = false
              this.$emit('on-success')
              this.$Notice.success({ desc: '编辑商品成功' })
            })
          }
        }
      })
    }
  }
}
</script>
